body {
  background: #fdfdfd;
  //margin-bottom: 60px;
}

#imagehref {
  text-decoration: none;
  border: none;
}

.menublock {
  margin: 0 0 7px 0;
  padding: 0;
  background: #D4DFEC;
  border: 1px solid #507AA1;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
}

.menublock h5 {
  background: #7797BA;
  text-align: center;
  text-shadow: -1px 1px 1px #444;
  color: #fff;
  font-weight: bold;
}

.menublock ul {
  list-style-image: url('/images/red_button.png');
}

.newsitem {
  margin-bottom: 0;
}

.newsitem h4 a {
  color: #2C4565;
  border-bottom: 2px solid #AA0000;
  &:hover {
    color: #AA0000;
    border-bottom: none;
  }
}

#footer {
  background: #3E628E;
  width: 100%;
  padding: 1% 0;
  color: #CCC;
  height: 60px;
  vertical-align: middle;
}

.req {
  color: red;
}

.loginform {
  display: block;
  float: left;
  padding-right: 15px;
}

.loginform fieldset {
  width: 300px;
}

.loginform label {
  display: inline-block;
  line-height: 1.2;
  width: 220px;
}

.loginform ol {
  margin: 0
}

.loginform li {
  list-style: none;
  padding: 5px;
  margin: 0;
}

.newsitem a {
  line-height: 1.5;
}

.clickable:hover {
  cursor: pointer;
}

.brieflist {
  margin-top: 10px;
}

.briefheadlinelist li:first-child {
  margin-top: 10px;
}

.search {
  height: 2em;
  width: 85%;
}

.audiofile {
  font-size: 1.5em;
}

.newsSearchList li {
  list-style: none;
}

.search-icon {
  font-size: 0.9em;
}

.form-error {
  background-color: red;
  color: white;
  margin-bottom: 30px;
}

.warning {
  color: red;
}



#main-nav {
  background: rgb(109,160,193);
  background: linear-gradient(180deg, rgba(109,160,193,1) 0%, rgba(49,80,127,1) 100%);
}

#content {
  background-color: #fdfdfd;
}

#main-content {
  padding: 10px;
}

#left-column {
  background: rgb(117,143,173);
  background: linear-gradient(270deg, rgba(117,143,173,1) 0%, rgba(217,238,241,1) 2%);
  padding: 10px;

}

#right-column {
  padding: 10px;
  background: rgb(117,143,173);
  background: linear-gradient(90deg, rgba(117,143,173,1) 0%, rgba(217,238,241,1) 2%);
}
